import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDropzone, Accept } from "react-dropzone";

import Box from "@mui/material/Box";

import { errorHandler } from "src/helpers/responseHandler";

export enum CATEGORY {
  "Video" = 1,
  "Audio" = 2,
  "Theory" = 3,
}

export const FILE_TYPES =
  "video/webm, audio/mpeg, audio/mp3, video/mp4" as unknown as Accept;

export const ACCEPT_TYPES = {
  1: { "video/*": [".mpv", ".mp4", ".mkv"] },
  2: { "audio/mp3": [".mp3"] },
  // 3: {
  //   "*/text": [
  //     "application/pdf",
  //     ".doc",
  //     ".docx",
  //     ".xls",
  //     ".xlsx",
  //     ".ppt",
  //     ".pptx",
  //     ".pages",
  //   ],
  // },
  3: {
    "application/pdf": [".pdf"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
  },
};

export const ACCEPTED_FILE_TYPES = {
  [CATEGORY.Video]: "video/mpv, video/mp4, video/mkv",
  [CATEGORY.Audio]: "audio/mp3", // audio/mpeg,
  [CATEGORY.Theory]: ".pdf,.doc,.docx", // .xls,.xlsx,.ppt,.pptx,.pages
};
export const MAX_SIZE = 33554432; // Byte to 32MB // Previously 2147483648 Bytes to 2GB
export const MAX_FILES = 1;

export interface IDropzone {
  maxFiles?: number;
  maxSize?: number;
  acceptedTypes?: Accept;
  onChange: (files: File[]) => void;
  children: React.ReactNode;
}

const Dropzone: React.FC<IDropzone> = ({
  maxFiles,
  maxSize,
  acceptedTypes,
  onChange,
  children,
}) => {
  const { t } = useTranslation();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    useFsAccessApi: false,
    accept: acceptedTypes || FILE_TYPES,
    maxSize: maxSize || MAX_SIZE,
    maxFiles: maxFiles || MAX_FILES,
    onDropRejected: (e) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (e?.[0]?.errors?.[0]?.code === "file-too-large") {
        errorHandler(t("errors.fileSizeMore32"));
      } else {
        errorHandler(e?.[0]?.errors?.[0]?.message);
      }
    },
  });

  React.useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      onChange(acceptedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  return (
    <Box {...getRootProps({ className: "flex-center h-full" })}>
      <input {...getInputProps()} />
      <Box className="video-drag-blk">{children}</Box>
    </Box>
  );
};

export default Dropzone;
